<template>
    <section>
      <v-card class="qr-code-scanner pa-4 custom-border border" height="65vh" dense>
        <div v-if="loading" class="text--center">
          <v-progress-circular size="20" indeterminate color="primary"></v-progress-circular>
          <p>Initializing camera...</p>
        </div>
        <qrcode-stream
          v-else-if="!showGrantPermission && !loading"
          @decode="onDecode"
          @error="onError"
          :constraints="{ facingMode: 'environment' }"
          :track="{ text: 'Nothing (default)', value: undefined }"
          :formats="['qr_code']"
          @camera-on="onCameraReady"
          @camera-error="onCameraError"
        />
        <div v-if="errorMessage" class="error--text">{{ errorMessage }}</div>
        <v-btn
          v-if="showGrantPermission"
          color="primary"
          class="poppins f11 fw500 text-capitalize my-5"
          @click="requestPermission"
          dense
        >
          Grant Camera Permission
        </v-btn>
      </v-card>
      <v-snackbar
        v-model="snackbar"
        color="primary"
        timeout="-1"
        vertical
        bottom
        absolute
      >
        <div class="f11">QR code details:</div>
        <div><b>{{ result }}</b></div>
  
        <template v-slot:action="{ attrs }">
          <v-btn text small :href="result" target="_blank">
            Open link in a new tab
            <v-icon right>
              mdi-open-in-new
            </v-icon>
          </v-btn>
          <v-btn color="white" icon v-bind="attrs" @click="snackbar = false" dense>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </section>
  </template>
  
  <script>
  import { QrcodeStream } from 'vue-qrcode-reader';
  
  export default {
    components: {
      QrcodeStream
    },
    data() {
      return {
        result: '',
        snackbar: false,
        errorMessage: '',
        showGrantPermission: true,
        loading: false
      };
    },
    methods: {
      async requestPermission() {
        try {
          this.loading = true;
          // Request access to the camera
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          // Stop the stream after requesting permission
          stream.getTracks().forEach(track => track.stop());
          this.errorMessage = '';
          this.showGrantPermission = false;
          this.loading = false;
        } catch (err) {
          this.onError(err);
        }
      },
  
      // Handle successful detection of a QR code
      onDecode(decodedResult) {
        this.result = decodedResult; // Store the scanned result
        this.snackbar = true;
        console.log("QR Code detected:", JSON.stringify(decodedResult));
      },
  
      // Handle errors
      onError(err) {
        this.loading = false;
        switch (err.name) {
          case 'NotAllowedError':
            this.errorMessage = 'Camera access permission is required.';
            this.showGrantPermission = true;
            break;
          case 'NotFoundError':
            this.errorMessage = 'No camera detected on this device.';
            break;
          case 'NotReadableError':
            this.errorMessage = 'Unable to access the camera.';
            break;
          case 'OverconstrainedError':
            this.errorMessage = 'No suitable cameras available.';
            break;
          case 'InsecureContextError':
            this.errorMessage = 'Camera access requires HTTPS or localhost.';
            break;
          default:
            this.errorMessage = err.message;
        }
        console.error("Camera error:", err);
      },
  
      onCameraError() {
        this.loading = false;
      },
  
      // Handle camera readiness
      onCameraReady() {
        this.loading = false;
        console.log("Camera is ready");
        this.errorMessage = ''; // Clear previous error messages
        this.showGrantPermission = false;
      }
    }
  }
  </script>
  
  <style>
  .qr-code-scanner {
    text-align: center !important;
  }
  
  .qrcode-stream-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
    .qrcode-stream-camera {
        border-radius: 4px !important;
    }

    /* Apply 80vw for mobile devices */
    @media only screen and (max-width: 768px) {
        .qrcode-stream-camera {
            width: 80vw !important;
        }
    }

    /* Apply 20vw for non-mobile devices */
    @media only screen and (min-width: 769px) {
        .qrcode-stream-camera {
            width: 40vw !important;
        }
    }

  
  .error {
    color: red;
    font-weight: bold;
  }
  
  .result {
    color: green;
    font-weight: bold;
    margin-top: 20px;
  }
  </style>
  